<template>
  <v-container fluid id="PropostaConsulta" class="pa-0">
    <v-toolbar-title
      v-bind:style="{ 'background-color': COR_PRINCIPAL }"
      class="headline lighten-2"
    >
      <!-- Título da página -->
      <v-toolbar-title class="text-white title-page mt-2 ml-4"
        >Propostas
      </v-toolbar-title>
      <!-- Fim Título da página -->

      <!-- Botões de ação cabeçalho -->
      <v-toolbar-title class="mt-0 mb-6 mr-4 ml-4 pa-0" dark elevation="0">
        <div class="d-flex" tabindex="-1">
          <!-- Text Field com Consulta ----------------------------------------->
          <v-container
            class="pa-0 ma-0 mb-7 d-flex"
            style="margin-top: -4px; max-width: 1200px"
          >
            <v-menu
              id="vMenu"
              ref="vMenu"
              v-model="menu"
              bottom
              right
              fluid
              offset-y
              origin="top"
              class="pa-0 d-flex flex-wrap"
              elevation="0"
              :close-on-content-click="false"
              @input="abriuConsulta"
            >
              <template v-slot:activator="{ on }">
                <div
                  ref="vMenuDiv"
                  class="false-filter d-flex align-center"
                  align="center"
                  v-on="on"
                >
                  <v-icon class="false-icon ml-3 mr-2" color="#FFF"
                    >mdi-magnify</v-icon
                  >

                  <div class="false-text flex-wrap">
                    <v-chip
                      v-if="store_Proposta.filtro.num_proposta"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Nº Proposta:
                      </span>
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.num_proposta
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.cliente_nome"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Cliente&nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.cliente_nome
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.cedente_nome"
                      style="max-width: 250px"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Ced/Cess &nbsp;</span
                      >
                      <strong :style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.cedente_nome
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.empreendimento"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1 pa-2"
                    >
                      <!-- <span   class="mt-n5 ml-2"  v-bind:style="{ 'color':COR_PRINCIPAL }">Empreendimento:</span><br>
                        <strong class="mt-4 ml-n16" v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Proposta.filtro.empreendimento.empreend_nome}}</strong> -->
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Empr:
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.empreendimento.empreend_nome
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.quadra"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Quadra&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.quadra
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.lote"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Lote&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.lote
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.prop_inicial"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Prop Inicial>=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        formatDate(store_Proposta.filtro.prop_inicial)
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.prop_final"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Prop Final&#60;=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        formatDate(store_Proposta.filtro.prop_final)
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.contr_data_inicial"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Contr Inicial>=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        formatDate(store_Proposta.filtro.contr_data_inicial)
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.contr_data_final"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Contr Final&#60;=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        formatDate(store_Proposta.filtro.contr_data_final)
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.corretor"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Corretor&nbsp;</span
                      >
                      {{ store_Proposta.filtro.corretor.corretor_nome }}
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.situacao"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        :style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Situação&nbsp;</span
                      >
                      {{ store_Proposta.filtro.situacao.situacao_nome }}
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.tipo_parcela"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Tipo Parcela:
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.tipo_parcela.text
                      }}</strong>
                    </v-chip>

                    <v-chip
                      v-if="store_Proposta.filtro.tipo_impressao"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.tipo_impressao
                      }}</strong>
                    </v-chip>

                    <!-- <v-chip
                        v-if="store_Proposta.filtro.proprietario"
                        pill
                        v-on="on"
                        small
                        class="ml-1 mr-1"
                      >
                        <span   v-bind:style="{ 'color':COR_PRINCIPAL }" style="opacity:0.7" >Proprietario:</span>
                        <strong v-bind:style="{ 'color':COR_PRINCIPAL }">{{store_Proposta.filtro.proprietario}}</strong>
                      </v-chip>                       -->
                    <v-chip
                      v-if="store_Proposta.filtro.pagamento_inicial_dt"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Pagamento Inicial>=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.pagamento_inicial_dt
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.pagamento_final_dt"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Pagamento Final&#60;=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.pagamento_final_dt
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.impressao_inicial_dt"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Impressão Inicial&#60;=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.impressao_inicial_dt
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.impressao_final_dt"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Impressão Final&#60;=
                      </span>
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.impressao_final_dt
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.numero_contrato"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Nº Contrato&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.numero_contrato
                      }}</strong>
                    </v-chip>

                    <v-chip
                      v-if="store_Proposta.filtro.num_cobranca"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Nº Documento&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">{{
                        store_Proposta.filtro.num_cobranca
                      }}</strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.opcoes_bloqueados"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <strong
                        v-if="
                          store_Proposta.filtro.opcoes_bloqueados.text == 'Não'
                        "
                        v-bind:style="{ color: COR_PRINCIPAL }"
                      >
                        Não Bloqueados
                      </strong>
                      <strong
                        v-if="
                          store_Proposta.filtro.opcoes_bloqueados.text == 'Sim'
                        "
                        v-bind:style="{ color: COR_PRINCIPAL }"
                      >
                        Bloqueados
                      </strong>
                    </v-chip>
                    <v-chip
                      v-if="store_Proposta.filtro.opcoes_parcela"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <span
                        v-bind:style="{ color: COR_PRINCIPAL }"
                        style="opacity: 0.7"
                        >Parcela&nbsp;</span
                      >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        {{ store_Proposta.filtro.opcoes_parcela.text }}</strong
                      >
                    </v-chip>

                    <v-chip
                      v-if="store_Proposta.filtro.opcoes_reajustados"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <strong
                        v-if="
                          store_Proposta.filtro.opcoes_reajustados.text == 'Não'
                        "
                        v-bind:style="{ color: COR_PRINCIPAL }"
                      >
                        Não Reajustados
                      </strong>
                      <strong
                        v-if="
                          store_Proposta.filtro.opcoes_reajustados.text == 'Sim'
                        "
                        v-bind:style="{ color: COR_PRINCIPAL }"
                      >
                        Reajustados
                      </strong>
                    </v-chip>
                    <v-chip
                      v-if="filtro_local.rescindidos"
                      pill
                      v-on="on"
                      small
                      class="ml-1 mr-1"
                    >
                      <strong v-bind:style="{ color: COR_PRINCIPAL }">
                        Rescindidos
                      </strong>
                    </v-chip>
                  </div>

                  <v-icon class="false-icon mr-4 ml-2" color="#FFF"
                    >mdi-filter-variant-plus</v-icon
                  >
                </div>
              </template>

              <v-container
                :style="{ 'background-color': COR_SECUNDARIA_2 }"
                style="
                  min-width: 800px !important;
                  max-width: 1000px !important;
                "
              >
                <v-row no-gutters class="linhas">
                  <v-col md="6">
                    <!-- Empreendimento ------------------------ -->
                    <v-autocomplete
                      :items="empreendimentos"
                      v-model="filtro_local.empreendimento"
                      item-text="empreend_nome"
                      item-value="cod_empreendimento"
                      label="Empreendimento"
                      placeholder="Empreendimento"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="3">
                    <!-- Quadra ----------------------- -->
                    <v-text-field
                      id="txtQuadra"
                      ref="txtQuadra"
                      dense
                      v-model="filtro_local.quadra"
                      cache-items
                      flat
                      label="Quadra"
                      placeholder="Quadra"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      tabindex="0"
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col md="3">
                    <!-- Lote ----------------------- -->
                    <v-text-field
                      id="txtLote"
                      ref="txtLote"
                      dense
                      v-model="filtro_local.lote"
                      cache-items
                      flat
                      label="Lote"
                      placeholder="Lote"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      tabindex="0"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="linhas">
                  <v-col md="6">
                    <!-- Empreendimento ------------------------ -->
                    <v-autocomplete
                      :items="corretores"
                      v-model="filtro_local.corretor"
                      item-text="corretor_nome"
                      item-value="cod_pessoa"
                      label="Corretor"
                      placeholder="Corretor"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col md="3">
                    <!-- Num Proposta ----------------------- -->
                    <v-text-field
                      id="txtProposta"
                      ref="txtProposta"
                      dense
                      v-model="filtro_local.num_proposta"
                      cache-items
                      flat
                      label="Nº Proposta"
                      placeholder="Nº Proposta"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      tabindex="0"
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col md="3">
                    <!-- Contrato ----------------------- -->
                    <v-text-field
                      id="txtContrato"
                      ref="txtContrato"
                      dense
                      v-model="filtro_local.numero_contrato"
                      cache-items
                      flat
                      label="Nº Contrato"
                      placeholder="Nº Contrato"
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      tabindex="0"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row no-gutters class="linhas">
                  <v-col md="6">
                    <!-- Cliente ------------------------ -->
                    <v-text-field
                      id="txtCliente"
                      ref="txtCliente"
                      dense
                      v-model="filtro_local.cliente_nome"
                      cache-items
                      flat
                      label="Cliente"
                      placeholder="Cliente (nome ou cpf)..."
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      tabindex="0"
                      class="mr-2"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col md="6">
                    <!-- Cliente ------------------------ -->
                    <v-text-field
                      id="txtCliente"
                      ref="txtCliente"
                      dense
                      v-model="filtro_local.cedente_nome"
                      cache-items
                      flat
                      label="Cedente / Cessionário"
                      placeholder="Cedente / Cessionário (nome ou cpf)..."
                      outlined
                      filled
                      background-color="#FFF"
                      clearable
                      tabindex="0"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row no-gutters class="linhas">
                  <v-col md="3" disabled
                    >+++
                    <!-- Vencimento Inicial ----------------------- -->
                    <DatePicker
                      id="txtPropInicial"
                      ref="txtPropInicial"
                      v-model="filtro_local.prop_inicial"
                      label="Proposta Inicial"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                  <v-col md="3">
                    <!-- Vencimento Final ----------------------- -->
                    <DatePicker
                      id="txtPropFinal"
                      ref="txtPropFinal"
                      v-model="filtro_local.prop_final"
                      label="Proposta Final"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                  <v-col md="3">
                    <!-- Proposta Inicial ----------------------- -->
                    <DatePicker
                      id="txtContratInicial"
                      ref="txtContratInicial"
                      v-model="filtro_local.contr_data_inicial"
                      label="Contrato Inicial"
                      placeholder="dia/mês/ano"
                      class="mr-2"
                      outlined
                      backgroundColor="#FFF"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                  <v-col md="3">
                    <!-- Proposta Final ----------------------- -->
                    <DatePicker
                      id="txtContratFinal"
                      ref="txtContratFinal"
                      v-model="filtro_local.contr_data_final"
                      label="Contrato Final"
                      placeholder="dia/mês/ano"
                      class="mr-0"
                      outlined
                      backgroundColor="#FFF"
                      :style="data_picker"
                      dense
                    >
                    </DatePicker>
                  </v-col>
                </v-row>
                <v-row no-gutters class="linhas">
                  <v-col md="3">
                    <!-- Situação ----------------------- -->
                    <!-- {{ opcoes_situacao }} -->
                    <v-autocomplete
                      :items="opcoes_situacao"
                      v-model="filtro_local.situacao"
                      item-text="situacao_nome"
                      item-value="cod_situacao"
                      label="Situação"
                      placeholder="Situação"
                      outlined
                      filled
                      background-color="#FFF"
                      dense
                      required
                      flat
                      clearable
                      tabindex="0"
                      return-object
                      class="mr-2"
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-container>

              <v-divider></v-divider>

              <!-- Botao Limpar ------------------------------------>
              <div
                class="pa-4 d-flex justify-space-between"
                v-bind:style="{ 'background-color': COR_SECUNDARIA }"
              >
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  outlined
                  tabindex="-1"
                  :small="isMobile"
                  @click="limpa_todos()"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                    >mdi-close</v-icon
                  >
                  <span>Limpar Todos</span>
                </v-btn>

                <!-- Botao Consultar ------------------------------------>
                <v-btn
                  class="text-white text-capitalize"
                  :color="COR_PRINCIPAL"
                  elevation="0"
                  tabindex="-1"
                  :small="isMobile"
                  @click="busca(1)"
                >
                  <v-icon class="mr-1 icon-filter" color="light-blue accent-2"
                    >mdi-magnify</v-icon
                  >
                  <span>Consultar</span>
                </v-btn>
              </div>
            </v-menu>
          </v-container>
          <!-- ----------------------------------------------- -->

          <v-spacer></v-spacer>

          <!-- Botão Novo ------------------------------- -->
          <v-btn
            v-on:click="novo()"
            class="text-white mt-1"
            :color="COR_PRINCIPAL"
            elevation="0"
            :small="isMobile"
          >
            <v-icon class="mr-1" color="green accent-2">mdi-plus</v-icon>
            <span class="">Novo</span>
          </v-btn>

          <!-- Botão Opções ------------------------------- -->
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="text-white mt-1 mb-n2 mr-2"
                :color="COR_PRINCIPAL"
                elevation="0"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-1" color="light-blue accent-2"
                  >mdi-view-headline</v-icon
                >
                <span>Opções</span>
              </v-btn>
            </template>

            <!-- Botões do Opções -->
            <v-list class="">
              <v-list-item v-on:click="novo()">
                <v-list-item-title class="">
                  <v-icon color="green" class="mr-2 icon-menu">mdi-plus</v-icon>
                  Novo
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="">
                  <v-icon color="blue" class="mr-2 icon-menu"
                    >mdi-pencil</v-icon
                  >
                  Editar
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item-title elevation="0" v-bind="attrs" v-on="on">
                        <v-icon color="blue" class="mr-2 icon-menu"
                          >mdi-download</v-icon
                        >
                        <span>Exportar</span>
                      </v-list-item-title>
                    </template>

                    <!-- Botões do Exportação -->
                    <v-list class="">
                      <v-list-item
                        @click="
                          export_table(cobrancas, 'xls', 'contas_receber')
                        "
                      >
                        <v-list-item-title class="">
                          <v-icon color="green" class="mr-2 icon-menu"
                            >mdi-file-excel</v-icon
                          >
                          Excel
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          export_table(cobrancas, 'csv', 'contas_receber')
                        "
                      >
                        <v-list-item-title class="">
                          <v-icon color="green" class="mr-2 icon-menu"
                            >mdi-microsoft-excel</v-icon
                          >
                          CSV
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        @click="
                          export_table(cobrancas, 'pdf', 'contas_receber')
                        "
                      >
                        <v-list-item-title class="">
                          <v-icon color="red" class="mr-2 icon-menu"
                            >mdi-file-pdf-box</v-icon
                          >
                          PDF
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar-title>
      <!-- Fim Botões de ação cabeçalho -->
    </v-toolbar-title>

    <v-row class="mt-n11 mx-0">
      <!-- Grade ----------------------------------->
      <v-col cols="12" class="pa-0 mx-0">
        <v-container
          class="container-principal container-rounded rounded-lg mx-0 px-0"
        >
          <!-- Conteiner  -->
          <v-data-table
            id="virtualScrollTable"
            ref="virtualScrollTable"
            :items="propostas"
            :headers="headers"
            :loading="loading"
            fixed-header
            dense
            style="white-space: nowrap"
            :height="tableHeight"
            :items-per-page="20"
            :hide-default-footer="true"
            :hide-default-header="isMobile"
            :page="currentPage || 1"
            loading-text="Carregando...  aguarde..."
            no-data-text="Nenhum Registro Encontrado"
            no-results-text="Nenhum Registro Encontrado"
          >
            <!-- @update:page="$vuetify.goTo($refs.virtualScrollTable)" -->
            <template #item="{ item }">
              <tr v-if="!isMobile">
                <td>
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="grey" dark icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list class="py-0">
                      <v-list-item
                        v-for="(opcao, i) in items"
                        :key="i"
                        @click="() => {}"
                        class="px-2"
                      >
                        <v-list-item-title v-if="opcao.title == 'Editar'">
                          <router-link
                            :to="'/propostacontainer/' + item.cod_empreendvenda"
                            class="nav-link"
                            aria-current="page"
                          >
                            <v-icon color="green" class="mr-2 icon-menu"
                              >mdi-pencil
                            </v-icon>
                            {{ opcao.title }}
                          </router-link>
                        </v-list-item-title>

                        <v-list-item-title v-else-if="opcao.title == 'Excluir'"
                          ><v-icon color="red" class="mr-2 icon-menu"
                            >mdi-delete</v-icon
                          >
                          {{ opcao.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </td>

                <td style="width: 8%">
                  <!-- {{ item.cod_empreendvenda }} -->
                </td>

                <td>
                  <span
                    v-if="item.situacao_nome == 'Concluido'"
                    class="status_ativo"
                    >{{ item.situacao_nome }}
                  </span>

                  <span
                    v-if="item.situacao_nome == 'Pendente'"
                    class="status_pendente"
                    >{{ item.situacao_nome }}
                  </span>

                  <span
                    v-if="item.situacao_nome == 'Cancelado'"
                    class="status_cancelado"
                    >{{ item.situacao_nome }}
                  </span>

                  <span
                    v-if="item.situacao_nome == 'Rescindido'"
                    class="status_cancelado"
                    >{{ item.situacao_nome }}
                  </span>
                  <span
                    v-if="item.situacao_nome == 'Em Andamento'"
                    class="status_pendente"
                    >{{ item.situacao_nome }}
                  </span>
                </td>

                <td style="width: 8%">
                  {{
                    item.empreendvenda_data_proposta
                      ? formatDate(item.empreendvenda_data_proposta)
                      : ""
                  }}
                </td>

                <td style="width: 15%">
                  {{ item.empreend_nome }}
                </td>

                <td style="width: ">
                  {{ item.quadra }}
                </td>

                <td style="width: ">
                  {{ item.lote }}
                </td>

                <td style="width: 15%">
                  <span class="customer mb-n1">{{ item.pessoa_nome }}</span>
                  <span class="caption email">{{
                    item.pessoa_fisica_juridica == "Física"
                      ? maskCpfCnpj(item.pessoa_cpf)
                      : maskCpfCnpj(item.pessoa_cnpj)
                  }}</span>
                </td>

                <td style="width: ">
                  {{ item.empreendvenda_contr_numero }}
                  {{
                    item.empreendvenda_contr_data
                      ? formatDate(item.empreendvenda_contr_data)
                      : ""
                  }}
                </td>

                <td>
                  <div style="display: flex" v-if="item.fone_celular1">
                    <a
                      style="
                        color: rgba(0, 0, 0, 0.87);
                        text-decoration: none;
                        display: flex;
                        text-align: center;
                        margin-bottom: 7px;
                      "
                      target="_blank"
                      :href="
                        'http://wa.me/55' +
                        item.fone_celular1
                          .replace('(', '')
                          .replace(')', '')
                          .replace('-', '')
                      "
                    >
                      <v-icon color="green">mdi-whatsapp </v-icon>
                      <div style="white-space: nowrap">
                        {{ item.fone_celular1 }}
                      </div>
                    </a>
                  </div>
                  <div style="display: flex" v-if="item.fone_residencial">
                    <v-icon color="gray">mdi-phone </v-icon>
                    <div style="white-space: nowrap">
                      {{ item.fone_residencial }}
                    </div>
                  </div>
                </td>
                <td style="width: 280px">
                  {{ item.pessoa_email }}
                </td>
                <td style="width: 280px">
                  <span class="width-adress"
                    >{{ item.sacado_end }}, {{ item.sacado_end_bairro }}</span
                  >
                  {{ item.sacado_end_cep }} - {{ item.sacado_end_cidade }}
                </td>
              </tr>

              <!-- MOBILE ------------------------------------------------------------------------------- -->
              <tr v-if="isMobile">
                <td style="width: auto">
                  <div class="mt-1" style="font-size: 14px; font-weight: 500">
                    {{ item.pessoa_nome }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.pessoa_email }}
                  </div>
                  <div class="mt-1" style="font-size: 12px">
                    {{ item.fone_celular1 }}
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-container>
      </v-col>
      <!-- Fim Grade ----------------------------------->
    </v-row>

    <!-- Paginacao ------------------------------ -->
    <div
      class="paginacao d-flex justify-end align-center"
      style="background-color: #ffffff"
    >
      <span style="color: grey; font-size: 14px" class="mr-3"
        >{{ count_dados }} linhas</span
      >
      <v-pagination
        id="pagination"
        ref="pagination"
        v-model="currentPage"
        :length="paginas"
        total-visible="5"
        @input="handlePageChange"
        aria-controls="dataTable"
        class="mt-n1"
        style="color: grey"
      />
    </div>

    <v-dialog
      v-model="dialog_excluir"
      persistent
      max-width="430"
      class="d-flex justify-center"
    >
      <v-divider></v-divider>

      <v-card elevation="0" class="d-flex justify-center mt-3 mb-3">
        <v-card-title class="text-h6">
          Tem certeza que deseja EXCLUIR ?
        </v-card-title>

        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn
            id="btn_cancelar"
            ref="btn_cancelar"
            class="mr-4"
            color="primary"
            text
            @click="dialog_excluir = false"
          >
            Cancelarrrrrr
          </v-btn>

          <v-btn
            class="btn white--text"
            color="primary accent-4"
            :loading="loading_excluir"
            @click="excluir(store_Proposta.pessoa_selecionado.cod_pessoa)"
          >
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="dialog_excluir_alert"
      :timeout="4000"
      :color="dialog_excluir_message"
      :centered="false"
      :bottom="false"
      :top="true"
      max-width="400px"
      height="400px"
      :multi-line="true"
      :vertical="true"
      elevation="5"
      :light="false"
      :outlined="false"
      rounded="5"
      :shaped="false"
    >
      {{ dialog_excluir_msg }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="dialog_excluir_alert = false"
          class="text-none"
          dark
        >
          <br />
          Fechar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import store_usuario from "../../store/store_usuario";
import store_Proposta from "./store_Proposta";
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SECUNDARIA_2,
} from "../../services/constantes";
import { maskCpfCnpj, formatDate } from "../../services/funcoes";
import DatePicker from "../ComponentesGlobais/Date-Picker/date-picker.vue";
import { API } from "../../services/API";

export default {
  name: "PropostaConsulta",
  components: {
    DatePicker,
  },

  data() {
    return {
      store_usuario: store_usuario,
      store_Proposta: store_Proposta,
      maskCpfCnpj: maskCpfCnpj,
      formatDate: formatDate,

      loading: false,
      search: null,
      search_input_focus: false,
      menu: false,
      empreendimentos: [],

      loading_excluir: false,
      filtro: null,
      currentPage: 1,

      COR_PRINCIPAL: COR_PRINCIPAL,
      COR_SECUNDARIA: COR_SECUNDARIA,
      COR_SECUNDARIA_2: COR_SECUNDARIA_2,
      accentColor: "#f00",
      dialog_excluir: false,
      dialog_excluir_alert: false,
      dialog_excluir_msg: false,
      dialog_excluir_message: null,
      dialogIndiceCadastro: false,
      opcoes_situacao: null,
      corretores: null,
      array_propostas: null,
      count_dados: null,
      paginas: null,

      filtro_local: {
        // Filtro na Tela de Consulta --------
        cliente_nome: null,
        cedente_nome: null,
        cod_empreendimento: null,
        empreendimento: null,
        data_inicial: null,
        data_final: null,

        quadra: null,
        lote: null,
        num_proposta: null,
        venc_inicial: null,
        venc_final: null,

        // Filtro Avancado --------------------
        situacao: null,
        proprietario: null,
        contrato: null,
        num_cobranca: null,
        tipo_parcela: {},
        parcela: null,
        tipo_impressao: null,
        bloqueados: null,
        reajustados: null,
        proposta_inicial: null,
        proposta_final: null,
        data_contrato: null,
        pagamento_inicial: null,
        pagamento_final: null,
        impressao_inicial: null,
        impressao_final: null,
        rescindidos: false,
      },

      data_picker: {
        "min-width": "150px !important",
        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        //'color':'red'
      },

      /* Opções */

      opcoes_tipo_parcela: [
        { id: 1, text: "Entrada", value: "ENTRADA" },
        { id: 2, text: "Parcela", value: "PARCELA" },
        { id: 3, text: "Renegociação", value: "RENEGOCIACAO" },
        { id: 4, text: "Residuo", value: "RESIDUO" },
        { id: 5, text: "Acordo", value: "ACORDO" },
        { id: 6, text: "Despesa", value: "DESPESA" },
        { id: 7, text: "Manual", value: "MANUAL" },
      ],

      opcoes_parcela: [
        { id: 1, text: "Mensal", value: "Mensal" },
        { id: 2, text: "Bimestral", value: "Bimestral" },
        { id: 3, text: "Trimestral", value: "Trimestral" },
        { id: 4, text: "Semestral", value: "Semestral" },
        { id: 5, text: "Anual", value: "Anual" },
      ],

      opcoes_tipo_impressao: [
        { id: 1, text: "Não Impressos", value: "Não Impressos" },
        {
          id: 2,
          text: "Impressos (Boletos e Antecipações)",
          value: "Impressos (Boletos e Antecipações)",
        },
        {
          id: 3,
          text: "Impressos (Somente Boletos)",
          value: "Impressos (Somente Boletos)",
        },
        {
          id: 4,
          text: "Impressos (Somente Antecipações)",
          value: "Impressos (Somente Antecipações)",
        },
      ],

      opcoes_bloqueados: [
        { id: 1, text: "Sim", value: "Sim" },
        { id: 2, text: "Não", value: "Não" },
      ],

      opcoes_reajustados: [
        { id: 1, text: "Sim", value: "Sim" },
        { id: 2, text: "Não", value: "Não" },
      ],

      /* Título container-rounded */
      headers: [
        {
          text: "",
          value: "",
        },
        { text: "Cod. Proposta", value: "cod_empreendvenda", sortable: true },
        { text: "Situação", value: "situacao_nome", sortable: true },
        {
          text: "Dt. Proposta",
          value: "empreendvenda_contr_data",
          sortable: true,
        },
        { text: "Empreendimento", value: "empreend_nome", sortable: true },
        { text: "Quadra", value: "quadra", sortable: true },
        { text: "Lote", value: "lote", sortable: true },
        { text: "Cliente", value: "pessoa_nome" },
        { text: "Contrato", value: "contrato" },
        { text: "Telefone", value: "telefone" },
        { text: "Email", value: "email" },
        { text: "Endereço", value: "endereco" },
      ],

      /* Tabela Consulta */
      propostas: [
        // {
        //   situacao: "Concluído",
        //   dt_proposta: "10/01/2022",
        //   empreendimento: "Parque do café",
        //   quadra: "01",
        //   lote: "01",
        //   cliente: "Fernando Garcia",
        //   email: "fernando@viscom.com.br",
        //   dt_contrato: "10/01/2022",
        //   contrato: "0050/2022",
        //   cod_proposta: "1112",
        //   endereco: "Rua Thomaz Gonzaga, 1806",
        //   bairro: "Centro",
        //   cep: "14409-696",
        //   cidade: "Franca - SP",
        // },
        // {
        //   situacao: "Pendente",
        //   dt_proposta: "11/01/2022",
        //   empreendimento: "Milano Residencial",
        //   quadra: "02",
        //   lote: "02",
        //   cliente: "Bruno Duarte",
        //   email: "bruno.iecisa@gmail.com",
        //   dt_contrato: "11/01/2022",
        //   contrato: "0051/2022",
        //   cod_proposta: "1113",
        //   endereco: "Rua Hygino Archetti Netto, 1904",
        //   bairro: "Jardim Cambuí",
        //   cep: "14409-696",
        //   cidade: "Franca - SP",
        // },
        // {
        //   situacao: "Cancelado",
        //   dt_proposta: "11/01/2022",
        //   empreendimento: "Jardim Piratininga",
        //   quadra: "03",
        //   lote: "03",
        //   cliente: "João Feliciano",
        //   email: "bruno.iecisa@gmail.com",
        //   dt_contrato: "11/01/2022",
        //   contrato: "0052/2022",
        //   cod_proposta: "1114",
        //   endereco: "Rua Thomaz Gonzaga, 1806",
        //   bairro: "Centro",
        //   cep: "14409-696",
        //   cidade: "Franca - SP",
        // },
        // {
        //   situacao: "Rescindido",
        //   dt_proposta: "11/01/2022",
        //   empreendimento: "Jardim Piratininga",
        //   quadra: "04",
        //   lote: "04",
        //   cliente: "Bernardo Nascimento",
        //   email: "bruno.iecisa@gmail.com",
        //   dt_contrato: "11/01/2022",
        //   contrato: "0053/2022",
        //   cod_proposta: "1115",
        //   endereco: "Rua Thomaz Gonzaga, 1806",
        //   bairro: "Centro",
        //   cep: "14409-696",
        //   cidade: "Franca - SP",
        // },
      ],

      /* Menu Edição (Button Dots) */
      items: [
        {
          title: "Editar",
        },
        {
          title: "Excluir",
        },
      ],
    };
  },

  async created() {
    //console.log('CREATED')
  },

  async mounted() {
    this.empreendimento_privilegio_acesso();
    this.getSituacoes();
    this.getCorretores();
  },

  watch: {
    search(val) {
      //console.log('WATCH', val)
      this.currentPage = 1;
    },
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },

    tableHeight() {
      if (this.isMobile) return window.innerHeight - 149 + 30 - 90;
      else return window.innerHeight - 149 - 55;
    },
  },

  methods: {
    handlePageChange(value) {
      this.currentPage = value;
    },
    limpa_todos() {
      for (let prob in this.filtro_local) {
        //console.log('limpando: ' , this.filtro_local[prob])
        this.filtro_local[prob] = null;
      }
      this.store_Proposta.filtro = {};
    },

    async busca() {
      this.loading = true;
      this.menu = false;
      this.propostas = [];

      this.store_Proposta.filtro = { ...this.filtro_local };

      var lo_params = { ...this.store_Proposta.filtro };

      if (!!this.store_Proposta.filtro.empreendimento?.cod_empreendimento) {
        lo_params.cod_empreendimento =
          this.store_Proposta.filtro.empreendimento.cod_empreendimento;
      }

      if (!!this.store_Proposta.filtro.corretor?.cod_pessoa) {
        lo_params.cod_corretor = this.store_Proposta.filtro.corretor.cod_pessoa;
      }

      if (!!this.store_Proposta.filtro.situacao?.cod_situacao) {
        lo_params.cod_situacao =
          this.store_Proposta.filtro.situacao.cod_situacao;
      }

      const resp = await API.get(`venda`, { params: { ...lo_params } });
      if (resp.status == 200 && resp.data.result !== "Não possui dados") {
        this.propostas = resp.data.result;
        this.count_dados = this.propostas.length;
        this.paginas = Math.ceil(this.count_dados / 20);
      } else {
        this.propostas = [];
      }

      this.loading = false;
    },

    async getCorretores() {
      var lo_params = {};
      const resp = await API.get("corretores", {});

      if (resp.status == 200) {
        this.corretores = resp.data.result;
      } else {
        this.corretores = [];
      }
    },
    async getSituacoes() {
      const resp = await API.get(`situacoes`, {
        params: { situacao_tipo: "Proposta de Venda" },
      });
      if (resp.status == 200) {
        this.opcoes_situacao = resp.data.result;
      } else {
        this.opcoes_situacao = [];
      }
    },

    async empreendimento_privilegio_acesso() {
      const resp = await API.get(`empreendimento_privilegio_acesso`);
      if (resp) {
        var la_Resp = resp.data.result;
        this.empreendimentos = la_Resp;
      }
    },
    abriuConsulta(val) {
      // console.log( 'abriuConsulta', this.dados_editar)
      if (val) {
        this.$nextTick(() => {
          this.filtro_local = { ...this.store_Proposta.filtro };
        });
      }
    },

    onBlur() {
      this.search_input_focus = false;
    },

    onFocus() {
      this.search_input_focus = true;
    },
  },
};
</script>

<style scoped>
.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #ffffff;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif !important;
}

.icon-filter {
  font-size: 20px;
}

.false-filter {
  background-color: #6c87a2;
  height: 38px !important;
  min-width: 400px !important;
  /* max-width: 900px!important; */
  border-radius: 5px;
  justify-content: space-between;
}

table tr {
  width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.status_ativo {
  font-size: 14px !important;
  background: #469c47;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_pendente {
  font-size: 14px !important;
  background: goldenrod;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.status_cancelado {
  font-size: 14px !important;
  background: #ff5252;
  color: #fff;
  padding: 2px 12px;
  border-radius: 25px;
  height: 24px;
}

.width-adress {
  display: block;
  max-width: 25ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

.customer {
  display: block;
  font-weight: 600;
}

.email {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
  opacity: 0.8;
}

.v-btn {
  display: inline-block;
}

.v-data-table-header-mobile {
  background-color: orangered;
  display: none !important;
}

.v-toolbar,
.v-sheet {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-toolbar__title {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.search-input,
.search-input-longo {
  min-width: 400px;
  max-width: 400px;
  margin: 0.8rem 0 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    /* min-width: calc(100% - 15px); */
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.container-principal {
  max-width: 100%;
  margin: auto !important;
}

.container-rounded {
  background: #fff !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

#virtual-scroll-table-tablet {
  display: none;
}

@media (max-width: 1024px) {
  .nav-link {
    padding-right: 0px !important;
  }
}

/* CELULAR */
@media (max-width: 599px) {
  .container-principal {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
  }

  .container-rounded {
    background: #fff !important;
    max-width: calc(100%) !important;
    margin: auto !important;
  }

  .nav-link {
    padding-left: 0px;
  }

  .theme--light.v-data-table {
    border-radius: 12px 12px 0px 0px !important;
  }

  .search-input {
    margin: 0.8rem 0 1.2rem 0;
    min-width: auto;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    /* min-width: calc(100% - 15px); */
  }

  .container-principal {
    max-width: 100% !important;
  }
}

.v-menu__content {
  z-index: 10 !important;
}
</style>
